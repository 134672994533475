import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="banner-links"
export default class extends Controller {
  static targets = [ "input", "label" ]
  connect() {
    console.log(this.inputTarget);
    console.log(this.labelTarget);
  }
}
