import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="banner-slider"
export default class extends Controller {
  static targets = [ "banner", "amount", "timer" ]

  index = 0

  connect() {
    this.bannerTargets.forEach((banner) => {
      banner.classList.add('fadeout')
      banner.style.zIndex = 0
    });

    this.bannerTargets[this.index % parseInt(this.amountTarget.innerText)].classList.remove('fadeout')
    this.bannerTargets[this.index % parseInt(this.amountTarget.innerText)].classList.add('fadein')

    this.refreshBanner = setInterval(() => {
      this.newBanner(this.index)
      this.index += 1
    }, (parseInt(this.timerTarget.innerText) * 1000));

    this.bannerTargets[index].classList.add('fadein')
  }

  disconnect() {
    clearInterval(this.refreshBanner)
  }

  newBanner(indexArgument) {
    const currentDiv = this.bannerTargets[indexArgument % parseInt(this.amountTarget.innerText)]
    const nextDiv = this.bannerTargets[(indexArgument + 1) % parseInt(this.amountTarget.innerText)]

    currentDiv.classList.toggle('fadein')
    currentDiv.classList.toggle('fadeout')
    nextDiv.style.zIndex = this.index + 2
    nextDiv.classList.toggle('fadein')
    nextDiv.classList.toggle('fadeout')
  }
}
