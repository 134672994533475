import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="page-refresh"
export default class extends Controller {
  // this connect is clearing the parameters of the url and preventing to continue sorting images on refresh

  connect() {
    history.replaceState({}, document.title, window.location.pathname);
  }



  // beforeUnload() {
  //   alert('do fish')
  // }
}
