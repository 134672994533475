import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="reasign-smth"
export default class extends Controller {
  static targets = [ "position", "form", "secret", "send" ]

  connect() {
  }

  newPositions(event) {
    event.preventDefault()
    const indices = []
    this.sendTarget.classList.add('d-none')
    this.formTarget.classList.remove('d-none')
    this.positionTargets.forEach(position => indices.push(position.innerText))
    this.secretTarget.value = indices.join('v')
  }
}
