import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "cancel", "form", "link", "position", "secret" ]

  connect() {
  }

  newPosition(event) {
    event.preventDefault()
    const indices = []
    this.positionTargets.forEach((position) => {
      indices.push(position.innerText)
    })
    this.secretTarget.value = indices.join('v')
    this.linkTarget.classList.add('d-none')
    this.formTarget.classList.remove('d-none')
    this.cancelTarget.classList.remove('d-none')
  }
}
