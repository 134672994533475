import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="projects-display"
export default class extends Controller {
  static targets = [ 'specials', 'fairs', 'link' ]

  connect() {
  }

  show(event) {
    event.preventDefault()
    this.linkTargets.forEach(link => link.classList.add('text-decoration-none'))
    event.currentTarget.classList.remove('text-decoration-none')
    if (event.currentTarget.innerText === 'Ferias') {
      this.specialsTarget.classList.add('d-none')
      this.fairsTarget.classList.remove('d-none')
    } else {
      this.specialsTarget.classList.remove('d-none')
      this.fairsTarget.classList.add('d-none')
    }
  }
}
