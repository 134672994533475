import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="display-sortable"
export default class extends Controller {
  static targets = [ "sorting", "title", "other", "open" ]

  connect() {
  }

  openSortable(event) {
    event.preventDefault()
    this.sortingTarget.classList.remove('d-none')
    this.titleTarget.classList.add('d-none')
    this.otherTarget.classList.add('d-none')
    this.openTarget.classList.add('d-none')
  }

  closeSortable(event) {
    event.preventDefault()
    this.sortingTarget.classList.add('d-none')
    this.titleTarget.classList.remove('d-none')
    this.otherTarget.classList.remove('d-none')
    this.openTarget.classList.remove('d-none')
  }
}
