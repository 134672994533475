import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="url-options"
export default class extends Controller {
  static targets = [ "urlwrapper", "uxlink", "uilink", "url", "innerurl", "category", "artists", "projects", "expos", "fairs", "builder", "bwrapper" ]

  connect() {
  }

  showUrlWrapper(event) {
    event.preventDefault()
    this.urlTarget.value = ""
    this.uilinkTarget.classList.add('d-none')
    this.uxlinkTarget.classList.add('d-none')
    this.urlwrapperTarget.classList.remove('d-none')
  }

  cancelExternalUrl(event) {
    event.preventDefault()
    this.uxlinkTarget.classList.remove('d-none')
    this.uilinkTarget.classList.remove('d-none')
    this.urlwrapperTarget.classList.add('d-none')
  }

  showInternalUrl(event) {
    event.preventDefault()
    this.urlTarget.value = ""

    this.bwrapperTarget.classList.remove('d-none')
    this.innerurlTarget.classList.remove('d-none')
    this.uxlinkTarget.classList.add('d-none')
    this.uilinkTarget.classList.add('d-none')
  }

  cancelInternalUrl(event) {
    event.preventDefault()

    this.artistsTarget.classList.add('d-none')
    this.projectsTarget.classList.add('d-none')
    this.exposTarget.classList.add('d-none')
    this.fairsTarget.classList.add('d-none')
    this.bwrapperTarget.classList.add('d-none')

    this.uxlinkTarget.classList.remove('d-none')
    this.uilinkTarget.classList.remove('d-none')
    this.innerurlTarget.classList.add('d-none')

    this.categoryTarget.value = ""
    this.builderTarget.innerText = ""
  }

  getCategory() {
    this.builderTarget.innerText = ""
    this.artistsTarget.value = ""
    this.exposTarget.value = ""
    this.projectsTarget.value = ""
    this.fairsTarget.value = ""

    if (this.categoryTarget.value === 'Artistas') {
      this.artistsTarget.classList.remove('d-none')
      this.exposTarget.classList.add('d-none')
      this.projectsTarget.classList.add('d-none')
      this.fairsTarget.classList.add('d-none')
    } else if (this.categoryTarget.value === 'Exposiciones') {
      this.artistsTarget.classList.add('d-none')
      this.exposTarget.classList.remove('d-none')
      this.projectsTarget.classList.add('d-none')
      this.fairsTarget.classList.add('d-none')
    } else if (this.categoryTarget.value === 'Proyectos Especiales') {
      this.artistsTarget.classList.add('d-none')
      this.exposTarget.classList.add('d-none')
      this.projectsTarget.classList.remove('d-none')
      this.fairsTarget.classList.add('d-none')
    } else if (this.categoryTarget.value === 'Ferias') {
      this.artistsTarget.classList.add('d-none')
      this.exposTarget.classList.add('d-none')
      this.projectsTarget.classList.add('d-none')
      this.fairsTarget.classList.remove('d-none')
    }
  }

  getUrl(event) {
    const modelId = event.currentTarget.value

    if (this.categoryTarget.value === 'Artistas') {
      this.builderTarget.innerText = `/artistas/${modelId}`
    } else if (this.categoryTarget.value === 'Proyectos Especiales') {
      this.builderTarget.innerText = `/projects/${modelId}`
    } else if (this.categoryTarget.value === 'Exposiciones') {
      this.builderTarget.innerText = `/projects/${modelId}`
    } else if (this.categoryTarget.value === 'Ferias') {
      this.builderTarget.innerText = `/projects/${modelId}`
    }

    this.urlTarget.value = this.builderTarget.innerText
  }
}
